import React from "react";
import { useFela } from "react-fela";
import useTranslation from "next-translate/useTranslation";

import { useTheme } from "@/hooks/useTheme";
import { Grid } from "@/components/Grid";
import { Heading } from "@/components/Heading";

import { DesignEngineElement } from "./DesignEngineElement";

export const DesignEngineSection: React.FC = () => {
  const { css } = useFela();
  const { colors, bp } = useTheme();
  const { t } = useTranslation();

  return (
    <Grid
      id="DesignEngineSection"
      customStyle={{
        position: "relative",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Heading as="h2" customStyle={{ textAlign: "center" }}>
        {t("section/designEngine:title")}
      </Heading>
      <p
        className={css({
          fontSize: 16,
          letterSpacing: 0.5,
          color: colors.GRAY,
          textAlign: "center",
          marginBottom: 16,
          [bp.FROM_TABLET]: {
            maxWidth: "50%",
          },
        })}
      >
        {t("section/designEngine:subtitle")}
      </p>
      <ul
        className={css({
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 30,
          padding: 0,
          [bp.FROM_TABLET]: {
            flexDirection: "row",
            marginTop: 48,
            justifyContent: "space-between",
            alignItems: "flex-start",
          },
        })}
      >
        <DesignEngineElement
          id="DesignEngineSectionElement#1"
          title={t("section/designEngine:elements.designFileConverter")}
          text={t("section/designEngine:elements.designFileConverterText")}
          scrollToId="upload"
          image={{
            src: "/svgs/files-converter.svg",
            alt: "Design File Converter",
          }}
        />
        <DesignEngineElement
          id="DesignEngineSectionElement#2"
          title={t("section/designEngine:elements.designFileApi")}
          text={t("section/designEngine:elements.designFileApiText")}
          href="/inspector"
          image={{
            src: "/svgs/magicul-inspector.svg",
            alt: "View XD Files",
          }}
        />
        <DesignEngineElement
          id="DesignEngineSectionElement#3"
          title={t("section/designEngine:elements.figmaBackup")}
          text={t("section/designEngine:elements.figmaBackupText")}
          href="/figma-design-backup"
          image={{
            src: "/svgs/backup-pot.svg",
            alt: "Backups for Figma",
          }}
        />
      </ul>
    </Grid>
  );
};
