import React from "react";
import useTranslation from "next-translate/useTranslation";
import { useFela } from "react-fela";

import { useTheme } from "@/hooks/useTheme";
import { Grid } from "@/components/Grid";
import { Heading } from "@/components/Heading";
import { Image } from "@/components/Image";

import { content } from "./content";

export const TrustedBySection: React.FC = () => {
  const { bp, colors } = useTheme();
  const { css } = useFela();
  const { logos } = content;
  const { t } = useTranslation();

  return (
    <Grid
      background="NAVY"
      customStyle={{
        flexDirection: "column",
        [bp.FROM_DESKTOP]: {
          padding: "56px 20px 65px",
        },
      }}
      id="TrustedBySection"
    >
      <Heading
        as="h2"
        customStyle={{ color: colors.WHITE, textAlign: "center" }}
      >
        {t("section/trustedBy:title")}
      </Heading>
      <div
        className={css({
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "column",
          flexWrap: "wrap",
          [bp.FROM_TABLET]: {
            flexDirection: "row",
            marginTop: 10,
          },
        })}
      >
        {logos.map(({ src, alt }) => (
          <Image
            key={`LOGO-${alt}`}
            src={src}
            alt={alt}
            className={css({
              opacity: 0.85,
              margin: "20px 10px",
            })}
          />
        ))}
      </div>
    </Grid>
  );
};
