import React from "react";
import { useFela } from "react-fela";

import { useTheme } from "../../../hooks/useTheme";
import { ITestimonialsElement } from "../types";
import { Image } from "../../../components/Image";

export const TestimonialsElement: React.FC<ITestimonialsElement> = ({
  author,
  authorTitle,
  text,
  companyLogo,
  authorAvatar,
  ...rest
}) => {
  const { css } = useFela();
  const { colors, bp, fonts } = useTheme();

  return (
    <li
      className={css({
        display: "flex",
        flexDirection: "column",
        width: "100%",
        borderRadius: 8,
        listStyle: "none",
        backgroundColor: colors.WHITE,
        padding: 24,
        fontFamily: fonts.PRIMARY_FONT,
        fontSize: 18,
        lineHeight: "29px",
        letterSpacing: 0.5,
        color: colors.DARK_GREY1,
        boxShadow: `20px 20px 80px 0px ${colors.CETACEAN_BLUE}`,
        nested: {
          ":not(:first-child)": {
            marginTop: 20,
            [bp.FROM_TABLET]: {
              marginTop: 30,
            },
            [bp.FROM_LARGE_DESKTOP]: {
              marginTop: 0,
            },
          },
        },
        [bp.FROM_TABLET]: {
          height: 330,
          width: 339,
        },
      })}
      {...rest}
    >
      <p
        className={css({
          marginBottom: 32,
          color: colors.DARK_GREY1,
          [bp.FROM_TABLET]: {
            height: 140,
            overflow: "hidden",
          },
        })}
      >
        {text}
      </p>
      <div
        className={css({
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 24,
        })}
      >
        <div>
          <p className={css({ color: colors.DARK_GREY1, fontWeight: 700 })}>
            {author}
          </p>
          <p className={css({ color: colors.DARK_GREY, fontSize: 14 })}>
            {authorTitle}
          </p>
        </div>
        <Image
          src={authorAvatar.src}
          alt={authorAvatar.alt}
          width={50}
          height={50}
          customStyle={{
            borderRadius: "50%",
          }}
        />
      </div>
      <Image
        src={companyLogo.src}
        alt={companyLogo.alt}
        customStyle={{
          height: 23,
        }}
      />
    </li>
  );
};
