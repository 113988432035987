import React, { forwardRef } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import { useFela } from "react-fela";

import { IWithCustomStyle } from "@/hooks/useTheme";
import { scrollTo } from "@/utils/scrollTo";
import { IButton, LinkButtonProps } from "@/components/LinkButton/types";
import { getButtonStyles } from "../Button/helpers/getButtonStyles";
import { Icon } from "../Icon";

const Button = forwardRef<HTMLAnchorElement, IButton & IWithCustomStyle>(
  (
    { onClick = () => null, href = "", title, customStyle, icon, children },
    ref,
  ) => {
    const { css } = useFela();
    return (
      <a
        href={href}
        onClick={onClick}
        ref={ref}
        className={css({ ...customStyle })}
      >
        {title || children}
        {icon && <Icon iconType={icon} />}
      </a>
    );
  },
);

export const LinkButton: React.FC<LinkButtonProps> = ({
  title,
  href = "",
  scrollToId = "upload",
  target,
  customStyle,
  icon,
  children,
}) => {
  const { pathname = "/" } = useRouter();
  const { css } = useFela();

  const defaultButtonStyles = getButtonStyles("PRIMARY");
  const buttonStyles = {
    ...defaultButtonStyles,
    padding: "0 46px",
  };

  const onClickHandler: React.MouseEventHandler<HTMLAnchorElement> = event => {
    event.preventDefault();
    scrollTo(scrollToId);
  };

  if (href) {
    return (
      <Link href={href}>
        <a target={target} className={css({ ...buttonStyles, ...customStyle })}>
          {title || children}
          {icon && <Icon iconType={icon} />}
        </a>
      </Link>
    );
  }

  return (
    <Link href={pathname} passHref>
      <Button
        onClick={onClickHandler}
        customStyle={{ ...buttonStyles, ...customStyle }}
        title={title}
        icon={icon}
      >
        {children}
      </Button>
    </Link>
  );
};
