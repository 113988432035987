import React, { useState } from "react";
import { useFela } from "react-fela";

import { useTheme } from "@/hooks/useTheme";
import { IWithCustomStyle } from "@/hooks/useTheme/types";

import { Icon, StyledIconWrapper } from "../Icon";
import { IAccordion } from "./types";

type IProps = IAccordion & IWithCustomStyle;

export const Accordion: React.FC<IProps> = ({
  title,
  icon,
  shortAnswer,
  longAnswer,
  toggle,
  active,
  openedIcon = "CLOSE-CIRCLED-ICON",
  closedIcon = "PLUS-CIRCLED-ICON",
  customWrapper,
  customStyle,
  customStyleContent,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { css } = useFela();
  const { bp, colors } = useTheme();

  const toggleAccordion = () => {
    if (!toggle) {
      setIsOpen(!isOpen);
      return;
    }
    toggle();
  };

  return (
    <div
      className={css({
        width: "100%",
        borderBottom: `1px solid ${colors.BRIGHT_GRAY}`,
        nested: {
          ":first-child": {
            borderTop: `1px solid ${colors.BRIGHT_GRAY}`,
          },
        },
        ...customWrapper,
      })}
    >
      <div
        className={css({
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 20px 0 5px",
          ...customStyle,
        })}
        onClick={toggleAccordion}
        role="button"
      >
        <div
          className={css({
            display: "flex",
            alignItems: "center",
            padding: "24px 0",
          })}
        >
          {icon && (
            <StyledIconWrapper wrapperStyle={{ flexShrink: 0, height: 18 }}>
              <Icon iconType={icon} />
            </StyledIconWrapper>
          )}
          <p
            className={css({
              fontSize: 18,
              lineHeight: "26px",
              color: colors.GRAY,
              fontWeight: 700,
              marginLeft: 20,
            })}
          >
            {title}
          </p>
        </div>
        <StyledIconWrapper
          wrapperStyle={{ flexShrink: 0, paddingLeft: "10px !important" }}
        >
          <Icon iconType={active || isOpen ? openedIcon : closedIcon} />
        </StyledIconWrapper>
      </div>
      <div
        className={css({
          maxHeight: `${active || isOpen ? 310 : 0}`,
          transition: `max-height linear .3s`,
          overflow: "hidden",
          ...customStyleContent,
        })}
      >
        <div
          className={css({
            padding: "0 15px",
            fontSize: 18,
            lineHeight: "26px",
            color: colors.GRAY,
            [bp.FROM_DESKTOP]: {
              padding: "0 35px",
            },
          })}
        >
          {shortAnswer && (
            <p className={css({ paddingBottom: 20 })}>{shortAnswer}</p>
          )}
          <p
            className={css({
              padding: "0 0 40px",
              nested: {
                "> span": {
                  display: "block",
                  nested: {
                    ":not(:first-child)": {
                      marginTop: 20,
                    },
                  },
                },
              },
            })}
          >
            {longAnswer}
          </p>
        </div>
      </div>
    </div>
  );
};
