import React from "react";
import useTranslation from "next-translate/useTranslation";
import { useFela } from "react-fela";

import { useTheme } from "@/hooks/useTheme";
import { Grid } from "@/components/Grid";
import { Heading } from "@/components/Heading";
import { Accordion } from "@/components/Accordion";

import Trans from "next-translate/Trans";

export const FaqSection: React.FC = () => {
  const { bp, colors } = useTheme();
  const { css } = useFela();
  const { t } = useTranslation("section/faq");

  return (
    <Grid
      customStyle={{
        flexDirection: "column",
        [bp.FROM_DESKTOP]: {
          padding: "56px 20px 36px",
        },
      }}
      id="FaqSection"
    >
      <Heading
        as="h2"
        customStyle={{ color: colors.CHARCOAL, textAlign: "center" }}
      >
        {t("title")}
      </Heading>
      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          [bp.FROM_TABLET]: {
            flexDirection: "row",
          },
        })}
      >
        <div
          className={css({
            width: "100%",
            [bp.FROM_TABLET]: {
              width: "calc(50% - 21px)",
              display: "inline-block",
              marginRight: 21,
            },
          })}
        >
          <Accordion
            title={t("symbolsComponents.question")}
            shortAnswer={t("shortAnswers.yes")}
            longAnswer={t("symbolsComponents.answer")}
            icon="RHOMB-ICON"
          />
          <Accordion
            title={t("prototypingTransitions.question")}
            longAnswer={
              <Trans
                i18nKey="section/faq:prototypingTransitions.answer"
                components={[<span key="0" />]}
              />
            }
            icon="ARROW-POINTER-ICON"
          />
          <Accordion
            title={t("benefitsOverHypermatic.question")}
            longAnswer={t("benefitsOverHypermatic.answer")}
            icon="BALANCE-ICON"
          />
          <Accordion
            title={t("conversionTime.question")}
            longAnswer={t("conversionTime.answer")}
            icon="TIME-BLUE-ICON"
          />
        </div>
        <div
          className={css({
            width: "100%",
            [bp.FROM_TABLET]: {
              width: "calc(50% - 21px)",
              display: "inline-block",
            },
          })}
        >
          <Accordion
            title={t("statesOverrides.question")}
            shortAnswer={t("shortAnswers.yes")}
            longAnswer={
              <Trans
                i18nKey="section/faq:statesOverrides.answer"
                components={[
                  <span key="0" />,
                  <span key="1" />,
                  <span key="2" />,
                ]}
              />
            }
            icon="COMPONENTS-ICON"
          />
          <Accordion
            title={t("freePlan.question")}
            longAnswer={t("freePlan.answer")}
            icon="FREE-ICON"
          />
          <Accordion
            title={t("benefitsOverSvg.question")}
            longAnswer={t("benefitsOverSvg.answer")}
            icon="FILE-ICON"
          />
        </div>
      </div>
    </Grid>
  );
};
