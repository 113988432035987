import { ITrustedByContent } from "./types";

export const content: ITrustedByContent = {
  logos: [
    {
      src: "/svgs/ibm-ix-white.svg",
      alt: "IBM",
    },
    {
      src: "/svgs/hp-white.svg",
      alt: "HP",
    },
    {
      src: "/svgs/google-white.svg",
      alt: "Google",
    },
    {
      src: "/svgs/deloitte-digital-white.svg",
      alt: "Deloitte Digital",
    },
    {
      src: "/svgs/purpose-white.svg",
      alt: "Purpose",
    },
  ],
};
