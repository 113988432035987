import React from "react";
import { useFela } from "react-fela";
import useTranslation from "next-translate/useTranslation";

import { useTheme } from "@/hooks/useTheme";
import { Grid } from "@/components/Grid";
import { ListElement } from "@/components/ListElement";
import { Heading } from "@/components/Heading";
import { Image } from "@/components/Image";

import dynamic from "next/dynamic";
import { LinkButtonProps } from "@/components/LinkButton/types";
import { ButtonProps } from "@/components/Button/types";
import { IDashboardScreenSection } from "./types";

const LinkButton = dynamic<LinkButtonProps>(() =>
  import("@/components/LinkButton").then(mod => mod.LinkButton),
);

const Button = dynamic<ButtonProps>(() =>
  import("@/components/Button").then(mod => mod.Button),
);

type IProps = IDashboardScreenSection & JSX.IntrinsicElements["div"];

export const DashboardScreenSection: React.FC<IProps> = ({
  elements,
  title,
  subtitle,
  text,
  src,
  scheduleDemoLabel,
  scheduleDemo,
  tryFreeLabel,
  tryFree,
  buttonLabel,
  onButtonClick,
  href,
  imageStyles,
  rightSidedImage = true,
  wrapperStyles,
  children,
  ...rest
}) => {
  const { css } = useFela();
  const { bp, colors } = useTheme();
  const { t } = useTranslation();

  const buttonStyles = {
    width: "auto",
    padding: "0 26px",
    marginTop: 40,
    boxShadow: "0 18px 54px 0 rgba(255, 111, 2, 0.5)",
  };

  return (
    <Grid
      id="DashboardSection"
      customStyle={{
        position: "relative",
        flexDirection: "column",
        [bp.FROM_DESKTOP]: {
          flexDirection: rightSidedImage ? "row" : "row-reverse",
          justifyContent: "space-between",
        },
      }}
      wrapperStyle={{
        backgroundColor: colors.LIGHT_GRAY2,
        background: "#fffffe url('/images/squares-bg.png') no-repeat",
        backgroundPosition: rightSidedImage ? "92% 95%" : "8% 92%",
        backgroundSize: "80%",
        [bp.FROM_TABLET]: {
          backgroundSize: rightSidedImage ? 440 : 400,
          backgroundPosition: rightSidedImage ? "99% 95%" : "8% 95%",
        },
        [bp.FROM_DESKTOP]: {
          backgroundSize: 440,
          backgroundPosition: rightSidedImage ? "80% 35%" : "20% 38%",
        },
        ...wrapperStyles,
      }}
      {...rest}
    >
      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 60,
          [bp.FROM_DESKTOP]: {
            alignItems: "flex-start",
            marginRight: rightSidedImage ? 80 : 0,
            marginLeft: rightSidedImage ? 0 : 80,
            maxWidth: "45%",
          },
        })}
      >
        <p
          className={css({
            marginBottom: 12,
            fontSize: 16,
            lineHeight: "24px",
            color: colors.LIGHT_GRAY,
            fontWeight: 600,
            textTransform: "uppercase",
          })}
        >
          {subtitle}
        </p>
        <Heading
          as="h2"
          customStyle={{
            [bp.FROM_DESKTOP]: {
              marginBottom: 24,
              textAlign: "left",
              fontSize: 36,
              lineHeight: "46px",
              color: colors.DARK_GREY,
            },
          }}
        >
          {title}
        </Heading>
        {text && (
          <p
            className={css({
              fontSize: 16,
              lineHeight: "24px",
              color: colors.DARK_GREY,
            })}
          >
            {text}
          </p>
        )}
        {children}

        {elements && (
          <ul
            className={css({
              margin: 0,
              padding: 0,
              listStyleType: "none",
            })}
          >
            {elements.map((element, index) => (
              <ListElement
                {...element}
                key={`ListElement-${element.id}-${index}`}
                wrapperStyle={{ marginTop: 19, alignItems: "center" }}
                textStyle={{
                  fontSize: 16,
                  lineHeight: "24px",
                  color: colors.DARK_GREY,
                }}
                badgeStyles={{
                  boxShadow: "none",
                }}
              />
            ))}
          </ul>
        )}

        {(tryFreeLabel || scheduleDemo) && (
          <div
            className={css({
              display: "flex",
              alignItems: "center",
              marginTop: 40,
            })}
          >
            {tryFreeLabel && (
              <Button
                id="ACTION#1"
                onClick={onButtonClick}
                customStyle={{ ...buttonStyles, marginTop: 0 }}
              >
                {tryFreeLabel || t("common:tryForFree")}
              </Button>
            )}
            {scheduleDemo && (
              <span
                className={css({
                  padding: "0 5px 0 24px",
                  fontWeight: 600,
                  fontSize: 14,
                  lineHeight: "26px",
                  letterSpacing: "0.64px",
                  color: colors.ORANGE,
                })}
              >
                {t("common:or")}
              </span>
            )}
            {scheduleDemo && (
              <LinkButton
                href={scheduleDemo}
                customStyle={{
                  height: "auto",
                  padding: 0,
                  border: "none",
                  backgroundColor: "none",
                  color: colors.ORANGE,
                  textTransform: "lowercase",
                  fontWeight: 600,
                  textDecoration: "underline",
                }}
                title={scheduleDemoLabel || t("common:scheduleDemo")}
                target="_blank"
              />
            )}
          </div>
        )}
        {!href && onButtonClick && !tryFreeLabel && (
          <Button
            id="ACTION#1"
            onClick={onButtonClick}
            customStyle={buttonStyles}
          >
            {buttonLabel || t("section/dashboardScreen:buttonDefaultLabel")}
          </Button>
        )}
        {href && (
          <LinkButton
            href={href}
            customStyle={buttonStyles}
            title={
              buttonLabel || t("section/dashboardScreen:buttonDefaultLabel")
            }
          />
        )}
      </div>
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          [bp.FROM_DESKTOP]: {
            maxWidth: "55%",
          },
        })}
      >
        <div
          className={css({
            width: "100%",
            marginBottom: 20,
            [bp.FROM_DESKTOP]: {
              margin: "0 auto",
            },
          })}
        >
          <Image
            customStyle={{
              maxWidth: 570,
              width: "100%",
              margin: "0 auto",
              borderRadius: 12,
              ...imageStyles,
            }}
            src={src}
            alt="Dashboard Screen"
          />
        </div>
      </div>
    </Grid>
  );
};
