import React from "react";
import { useFela } from "react-fela";

import { Image } from "@/components/Image";
import { Heading } from "@/components/Heading";
import useTranslation from "next-translate/useTranslation";
import { LinkButton } from "@/components/LinkButton";
import { IDesignEngineElement } from "../types";
import { useTheme } from "../../../hooks/useTheme";

export const DesignEngineElement: React.FC<IDesignEngineElement> = ({
  title,
  text,
  image,
  href,
  scrollToId,
  ...rest
}) => {
  const { css } = useFela();
  const { colors, bp } = useTheme();
  const { t } = useTranslation();

  return (
    <li
      className={css({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flexBasis: "33%",
        nested: {
          ":not(:last-child)": {
            paddingBottom: 50,
          },
        },
        [bp.FROM_TABLET]: {
          nested: {
            ":not(:last-child)": {
              paddingBottom: 0,
            },
          },
        },
      })}
      {...rest}
    >
      <Image
        src={image.src}
        alt={image.alt}
        wrapperStyle={{ height: 166, alignItems: "center" }}
      />
      <Heading
        as="h3"
        customStyle={{
          margin: "50px auto 16px",
          color: colors.DARK_GREY,
          textAlign: "center",
        }}
      >
        {title}
      </Heading>
      <p
        className={css({
          fontSize: 14,
          lineHeight: "22px",
          color: colors.GRAY,
          textAlign: "center",
          marginBottom: 32,
          padding: "0 50px",
        })}
      >
        {text}
      </p>
      <LinkButton
        title={t("common:learnMore")}
        href={href}
        customStyle={{
          width: "fit-content",
          height: 24,
          fontSize: 16,
          lineHeight: "24px",
          padding: 0,
          color: colors.ORANGE,
          backgroundColor: "none",
          border: "none",
          textTransform: "none",
          nested: {
            "> svg": {
              marginLeft: 13,
              transform: "rotate(-90deg)",
              nested: {
                "> path": {
                  fill: colors.ORANGE,
                },
              },
            },
          },
        }}
        scrollToId={scrollToId || undefined}
        icon="CHEVRON"
      />
    </li>
  );
};
