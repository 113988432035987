
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import React from "react";
import useTranslation from "next-translate/useTranslation";

import { Head } from "@/components/Head";
import { useTheme } from "@/hooks/useTheme";
import { Converter } from "@/containers/Converter";

import { MagicianSection } from "@/sections/MagicianSection";
import { TrustedBySection } from "@/sections/TrustedBySection";
import { TestimonialsSection } from "@/sections/TestimonialsSection";
import { FaqSection } from "@/sections/FaqSection";
import { DesignEngineSection } from "@/sections/DesignEngineSection";
import { DashboardScreenSection } from "@/sections/DashboardScreenSection";
import { RequestDemoSection } from "@/sections/RequestDemoSection";
import { useInquiryForm } from "@/hooks/useInquiryForm";
import { ConverterFormatsList } from "@/components/ConverterFormatsList";
import { FormSourceType } from "@/containers/InquiryForm";

const Pages = () => {
  const { t } = useTranslation();
  const { bp } = useTheme();
  const { toggleInquiryForm } = useInquiryForm();

  return (
    <>
      <Head pageNamespace="landingPage" ogImage="/images/main-og-image.jpg" />

      <MagicianSection
        title={t("landingPage:title")}
        subtitle={t("landingPage:subtitle")}
        suggestFeature={false}
        showStartsAt={false}
        cta={{
          title: t("section/magicians:scrollTo"),
          scrollToId: "DesignEngineSection",
        }}
        customStyle={{
          padding: 20,
          justifyContent: "flex-start",
          [bp.FROM_TABLET]: {
            justifyContent: "flex-start",
          },
          [bp.FROM_DESKTOP]: {
            justifyContent: "flex-start",
          },
          nested: {
            "& > div": {
              maxWidth: 560,
              marginTop: 123,
              [bp.FROM_DESKTOP]: {
                marginTop: 103,
              },
              nested: {
                ":last-child": {
                  marginTop: 27,
                  nested: {
                    "> img": {
                      display: "block",
                      width: "100%",
                    },
                  },
                },
                "& > p": {
                  maxWidth: "fit-content",
                  lineHeight: "26px",
                },
              },
            },
          },
        }}
      >
        <Converter />
      </MagicianSection>

      <TrustedBySection />
      <DesignEngineSection />

      <DashboardScreenSection
        title={t("landingPage:section/convertDesign.title")}
        subtitle={t("landingPage:section/convertDesign.subtitle")}
        text={t("landingPage:section/convertDesign.text")}
        src="/images/converter-screen.webp"
        wrapperStyles={{ backgroundColor: "#fffffe" }}
      >
        <ConverterFormatsList
          title={t("landingPage:section/formatsList.title")}
        />
      </DashboardScreenSection>

      <DashboardScreenSection
        title={t("landingPage:section/figmaBackup.title")}
        subtitle={t("landingPage:section/figmaBackup.subtitle")}
        text={t("landingPage:section/figmaBackup.text")}
        src="/images/figma-backup-screen.webp"
        href="/figma-design-backup"
        buttonLabel={t("common:learnMore")}
        rightSidedImage={false}
        wrapperStyles={{ backgroundColor: "#fffffe" }}
        imageStyles={{ maxWidth: 700 }}
      />

      <DashboardScreenSection
        title={t("landingPage:section/buildPipeline.title")}
        subtitle={t("landingPage:section/buildPipeline.subtitle")}
        text={t("landingPage:section/buildPipeline.text")}
        src="/images/inspector-preview.webp"
        href="/inspector"
        buttonLabel={t("common:learnMore")}
        wrapperStyles={{ backgroundColor: "#fffffe" }}
      />

      <TestimonialsSection />
      <FaqSection />

      <RequestDemoSection
        title={t("section/requestDemo:title")}
        subtitle={t("section/requestDemo:subtitle")}
        buttonLabel={t("section/requestDemo:buttonLabel")}
        wrapperBgColor="#F7F8FD"
        onButtonClick={() => toggleInquiryForm(FormSourceType.REQUEST_DEMO)}
      />
    </>
  );
};

export default Pages;


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  