import React from "react";
import { useRouter } from "next/router";
import { useFela } from "react-fela";
import useTranslation from "next-translate/useTranslation";

import { IWithCustomStyle } from "@/hooks/useTheme/types";
import { useTheme } from "@/hooks/useTheme";
import { getConversionDetails } from "@/containers/Converter/common/helpers";
import { formatToIconMap, landingPagesMap } from "@/containers/Converter/maps";
import { IConversionType } from "@/containers/Converter/types";
import { Icon, StyledIconWrapper } from "../Icon";
import { Heading } from "../Heading";
import { LinkButton } from "../LinkButton";

interface IConverterFormatsList {
  title: string;
}

interface IConverterFormatsItem {
  id: number;
  conversionType: IConversionType;
}

const elements: IConverterFormatsItem[] = [
  {
    id: 0,
    conversionType: "XD_TO_SKETCH",
  },
  {
    id: 1,
    conversionType: "XD_TO_FIGMA",
  },
  {
    id: 2,
    conversionType: "FIGMA_TO_SKETCH",
  },
  {
    id: 3,
    conversionType: "FIGMA_TO_XD",
  },
  {
    id: 4,
    conversionType: "XD_TO_PSD",
  },
];

export const ConverterFormatsList: React.FC<
  IConverterFormatsList & IWithCustomStyle
> = ({ title, customStyle = {} }) => {
  const { css } = useFela();
  const { colors, bp } = useTheme();
  const { pathname } = useRouter();

  const { t } = useTranslation();

  return (
    <div
      className={css({
        ...customStyle,
      })}
    >
      <Heading
        as="h4"
        customStyle={{
          margin: "40px 0 16px",
          color: "#7D8894",
          fontWeight: "bold",
          textAlign: "left",
        }}
      >
        {title}
      </Heading>
      <div
        className={css({
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        })}
      >
        {elements.map(({ id, conversionType }) => {
          const { conversionSource, conversionTarget } = getConversionDetails(
            conversionType,
          );
          const sourceIcon = formatToIconMap[conversionSource];
          const targetIcon = formatToIconMap[conversionTarget];
          const currentHref = landingPagesMap[conversionType];
          const href = currentHref === pathname ? "" : currentHref;
          const scrollToId = currentHref === pathname ? "HEADER" : "";

          return (
            <LinkButton
              title=""
              href={href}
              scrollToId={scrollToId}
              key={id}
              customStyle={{
                height: 56,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: 16,
                marginBottom: 16,
                backgroundColor: colors.WHITE,
                boxShadow: "20px 20px 80px rgba(16, 5, 48, 0.08)",
                borderRadius: 8,
                textDecoration: "none",
                border: "none",
                [bp.FROM_TABLET]: {
                  flexBasis: "calc(50% - 8px)",
                  margin: "0 5px 16px 0",
                },
              }}
            >
              <StyledIconWrapper
                customStyle={{ maxWidth: 24, height: 24 }}
                wrapperStyle={{ maxHeight: 24 }}
              >
                <Icon iconType={sourceIcon} />
              </StyledIconWrapper>
              <StyledIconWrapper
                customStyle={{
                  margin: "0 8px",
                  nested: { "> path": { stroke: colors.DARK_GREY1 } },
                }}
              >
                <Icon iconType="ARROW-RIGHT" />
              </StyledIconWrapper>
              <StyledIconWrapper
                customStyle={{ maxWidth: 24, height: 24 }}
                wrapperStyle={{ maxHeight: 24 }}
              >
                <Icon iconType={targetIcon} />
              </StyledIconWrapper>
              <p
                className={css({
                  fontSize: 16,
                  lineHeight: "24px",
                  marginLeft: 16,
                  fontWeight: 700,
                  color: colors.DARK_GREY,
                  textTransform: "initial",
                })}
              >
                {t("navigation:converterDropdown.convert", {
                  from: t(`fileTypes:${conversionSource}` as string),
                  to: t(`fileTypes:${conversionTarget}` as string),
                })}
              </p>
            </LinkButton>
          );
        })}
        <LinkButton
          title={t("landingPage:section/formatsList.allFormats")}
          customStyle={{
            width: "fit-content",
            flexBasis: "calc(50% - 8px)",
            fontSize: 16,
            lineHeight: "24px",
            height: 24,
            padding: 0,
            margin: "16px 0",
            color: colors.ORANGE,
            backgroundColor: "none",
            border: "none",
            textTransform: "none",
            nested: {
              "> svg": {
                marginLeft: 23,
                nested: {
                  "> path": {
                    stroke: colors.ORANGE,
                  },
                },
              },
            },
          }}
          scrollToId="HEADER"
          icon="ARROW-RIGHT"
        />
      </div>
    </div>
  );
};
