import React from "react";
import { useFela } from "react-fela";
import useTranslation from "next-translate/useTranslation";

import { Grid } from "@/components/Grid";
import { Heading } from "@/components/Heading";
import { LinkButton } from "@/components/LinkButton";

import { Image } from "@/components/Image";
import { useTheme } from "@/hooks/useTheme";
import { CALENDLY_LINK_PRODUCT_DEMO } from "@/constants/pricing";
import { IRequestDemoSection } from "./types";

type IProps = IRequestDemoSection & JSX.IntrinsicElements["div"];

export const RequestDemoSection: React.FC<IProps> = ({
  title,
  subtitle,
  wrapperBgColor = "#fff",
  buttonLabel,
  href,
  onButtonClick,
  requestDemoLink = CALENDLY_LINK_PRODUCT_DEMO,
  ...rest
}) => {
  const { css } = useFela();
  const { bp, colors } = useTheme();
  const { t } = useTranslation();

  return (
    <Grid
      id="RequestDemoSection"
      customStyle={{
        flexDirection: "column",
        [bp.FROM_TABLET]: {
          flexDirection: "row",
        },
      }}
      wrapperStyle={{
        backgroundColor: wrapperBgColor,
      }}
      {...rest}
    >
      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          [bp.FROM_TABLET]: {
            flexBasis: "80%",
            alignItems: "flex-start",
          },
        })}
      >
        {subtitle && (
          <p
            className={css({
              fontSize: 18,
              lineHeight: "26px",
              letterSpacing: 0.64,
              color: colors.GRAY,
              fontWeight: 700,
            })}
          >
            {subtitle}
          </p>
        )}
        <Heading
          as="h2"
          customStyle={{
            marginBottom: 40,
            color: colors.DARK_GREY,
            textAlign: "left",
            letterSpacing: "-0.01rem",
          }}
        >
          {title}
        </Heading>

        <LinkButton
          href={requestDemoLink}
          target="_blank"
          customStyle={{ maxWidth: 190 }}
          title={t("common:scheduleDemo")}
        />
      </div>
      <div
        className={css({
          display: "flex",
          justifyContent: "center",
          [bp.FROM_TABLET]: {
            maxWidth: 429,
            flexBasis: "20%",
          },
        })}
      >
        <Image src="/svgs/magician-files.svg" alt="Magician with files" />
      </div>
    </Grid>
  );
};
