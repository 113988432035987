import React from "react";
import { useFela } from "react-fela";

import { useTheme } from "@/hooks/useTheme";
import { Badge } from "@/components/Badge";
import { Icon } from "@/components/Icon";

import { IListElement } from "./types";

export const ListElement: React.FC<IListElement> = ({
  iconType,
  title,
  text,
  wrapperStyle = {},
  titleStyle = {},
  textStyle = {},
  badgeStyles = {},
  ...rest
}) => {
  const { css } = useFela();
  const { colors, fonts } = useTheme();

  return (
    <li
      className={css({
        display: "flex",
        alignItems: "flex-start",
        nested: {
          ":not(:first-child)": {
            marginTop: 23,
          },
        },
        ...wrapperStyle,
      })}
      {...rest}
    >
      {iconType && (
        <Badge
          size={32}
          customStyle={{
            flexShrink: 0,
            backgroundColor: colors.WHITE,
            boxShadow: `4px 8px 8px 0 ${colors.CETACEAN_BLUE}`,
            ...badgeStyles,
          }}
        >
          <Icon iconType={iconType} />
        </Badge>
      )}

      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          marginLeft: 15,
        })}
      >
        {title && (
          <p
            className={css({
              marginBottom: 5,
              fontSize: 18,
              lineHeight: 1.44,
              fontWeight: "bold",
              fontFamily: fonts.PRIMARY_FONT,
              color: colors.CHARCOAL,
              ...titleStyle,
            })}
          >
            {title}
          </p>
        )}
        {text && (
          <p
            className={css({
              fontSize: 14,
              lineHeight: 1.71,
              color: colors.DARK_GREY,
              ...textStyle,
            })}
          >
            {text}
          </p>
        )}
      </div>
    </li>
  );
};
