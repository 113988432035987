import React from "react";
import { useFela } from "react-fela";
import useTranslation from "next-translate/useTranslation";

import { useTheme } from "@/hooks/useTheme";
import { Grid } from "@/components/Grid";
import { Heading } from "@/components/Heading";

import { TestimonialsElement } from "./TestimonialsElement";

export const TestimonialsSection: React.FC = () => {
  const { css } = useFela();
  const { colors, bp } = useTheme();
  const { t } = useTranslation();

  return (
    <Grid
      id="TestimonialsSection"
      customStyle={{
        position: "relative",
        flexDirection: "column",
      }}
      wrapperStyle={{ backgroundColor: colors.LIGHT_BLUE }}
    >
      <Heading as="h2" customStyle={{ textAlign: "center" }}>
        {t("section/testimonials:title")}
      </Heading>
      <p
        className={css({
          fontSize: 16,
          letterSpacing: 0.5,
          color: colors.GRAY,
          textAlign: "center",
          marginBottom: 16,
        })}
      >
        {t("section/testimonials:subtitle")}
      </p>
      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          flexWrap: "wrap",
          marginTop: 30,
          [bp.FROM_DESKTOP]: {
            margin: "70px 0 49px",
            flexWrap: "wrap",
            alignItems: "center",
          },
          [bp.FROM_LARGE_DESKTOP]: {
            flexDirection: "row",
            justifyContent: "space-between",
          },
        })}
      >
        <TestimonialsElement
          id="TestimonialsElement#1"
          author="Onur Ada"
          authorTitle={t("section/testimonials:elements.firstAuthorTitle")}
          text={t("section/testimonials:elements.firstAuthorText")}
          authorAvatar={{
            src: "/images/user-avatar1.jpg",
            alt: "Onur Ada Avatar",
          }}
          companyLogo={{
            src: "/svgs/ibm-ix.svg",
            alt: "IBM",
          }}
        />
        <TestimonialsElement
          id="TestimonialsElement#2"
          author="Hayyaan Ahmad"
          authorTitle={t("section/testimonials:elements.secondAuthorTitle")}
          text={t("section/testimonials:elements.secondAuthorText")}
          authorAvatar={{
            src: "/images/user-avatar2.jpg",
            alt: "Hayyaan Ahmad Avatar",
          }}
          companyLogo={{
            src: "/svgs/amazon.svg",
            alt: "Amazon",
          }}
        />
        <TestimonialsElement
          id="TestimonialsElement#3"
          author="Will Kaufman"
          authorTitle={t("section/testimonials:elements.thirdAuthorTitle")}
          text={t("section/testimonials:elements.thirdAuthorText")}
          authorAvatar={{
            src: "/images/user-avatar3.jpg",
            alt: "Will Kaufman Avatar",
          }}
          companyLogo={{
            src: "/svgs/convertiv.svg",
            alt: "Convertiv",
          }}
        />
      </div>
    </Grid>
  );
};
